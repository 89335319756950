import React from 'react';
import Helmet from 'react-helmet';
import { graphql } from "gatsby"
// import '../css/blog-post.css';


class Template extends React.Component {
  render() {
    const { markdownRemark: post } = this.props.data;
    let htmlRaw;

    // if (post.frontmatter.type == "WEB" || post.frontmatter.type == "WEB-test") {
    htmlRaw = (< div className="blog-post" >
      <div className="blog-post-content" dangerouslySetInnerHTML={{ __html: post.html }} />
    </div >)
    // } else {
    //   htmlRaw = (< div className="blog-post" >
    //     <h1>{post.frontmatter.title}</h1>
    //     <div className="blog-post-content" dangerouslySetInnerHTML={{ __html: post.html }} />
    //   </div >);
    // }

    return (
      <div className="blog-post-container" >
        <Helmet title={`AgroLIFE - ${post.frontmatter.title}`} />
        {htmlRaw}
      </div>
    )
  }
}
export default Template

export const pageQuery = graphql`
  query BlogPostByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
        type
      }
    }
  }
`
  ;